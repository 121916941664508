var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "pb-4", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6", sm: "6", md: "4", lg: "4" } },
            [_c("ViewAsCustomer")],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6", sm: "6", md: "4", lg: "4" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dateMenu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label: "Date range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.dateRangeText,
                                    callback: function($$v) {
                                      _vm.dateRangeText = $$v
                                    },
                                    expression: "dateRangeText"
                                  }
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dateMenu,
                    callback: function($$v) {
                      _vm.dateMenu = $$v
                    },
                    expression: "dateMenu"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: { range: "" },
                    model: {
                      value: _vm.dates,
                      callback: function($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6", sm: "6", md: "4", lg: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "table" } })
        : _c("v-data-table", {
            attrs: {
              "item-key": "index",
              headers: _vm.computedHeaders,
              items: _vm.filteredMailings,
              "item-class": _vm.rowColor,
              "items-per-page": 50,
              search: _vm.search,
              "custom-sort": _vm.tableSort,
              expanded: _vm.expanded,
              "sort-by": ["scheduleDateLocal"],
              "sort-desc": "",
              dense: ""
            },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              },
              "click:row": _vm.toggleExpandRow
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "px-0",
                        attrs: { colspan: headers.length }
                      },
                      [
                        _c("v-simple-table", {
                          attrs: { dense: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Campaign Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Mailing Item Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Tenant CRM Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  TA Id\n                "
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(item.campaignId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.mailingItemId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.tenantCrmId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.targetAudienceId))
                                        ])
                                      ])
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("v-divider"),
                        _c("v-simple-table", {
                          attrs: { dense: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  List Name\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Mailing Name\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Aco Mailing Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Job Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Job Result\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Failed Reason\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Sent Count\n                "
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(item.acousticMailings, function(
                                        acoMailing
                                      ) {
                                        return _c(
                                          "tr",
                                          { key: acoMailing.id },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(acoMailing.id))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.listName)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.mailingName)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.mailingId)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(acoMailing.jobId))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  acoMailing.jobStatusResult
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  acoMailing.failedStatusReason
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.sendCount)
                                              )
                                            ])
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm.$_dashMixin_getMailingIconColor(
                                            item
                                          ),
                                          left: ""
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$_dashMixin_getMailingIcon(item)
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(item.statusDisplay))])]
                    )
                  ]
                }
              },
              {
                key: "item.distribution",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(item.distribution.join(", ")) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.modifiedOnLocal",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("displayDateTimeFormat")(item.modifiedOnLocal)
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.scheduleDateLocal",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("displayDateTimeFormat")(
                            item.scheduleDateLocal
                          )
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.sentOnLocal",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("displayDateTimeFormat")(item.sentOnLocal)
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.taCount",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.taNthSubCount === null
                      ? _c("div", [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm._f("commaNumber")(item.taCount)) +
                              "\n      "
                          )
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("commaNumber")(item.taNthSubCount)
                              ) +
                              "/" +
                              _vm._s(_vm._f("commaNumber")(item.taCount)) +
                              "\n      "
                          )
                        ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onTooltip = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mx-1",
                                        attrs: { small: "", color: "info" },
                                        on: {
                                          click: function($event) {
                                            _vm.mailingItemIdDialog =
                                              item.mailingItemId
                                            _vm.htmlDialog = true
                                          }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      Object.assign({}, onTooltip)
                                    ),
                                    [
                                      _vm._v(
                                        "\n            mdi-text-box\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Mailing Details")])]
                    ),
                    _vm.$_global_hasRouteClaims("MailingResultsDashboard") ||
                    _vm.$_global_hasRouteClaims("SingleMailingReport")
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var onTooltip = ref.on
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onMenu = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "mx-1",
                                                            attrs: {
                                                              small: "",
                                                              color: "info"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        Object.assign(
                                                          {},
                                                          onTooltip,
                                                          onMenu
                                                        )
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                mdi-chart-box\n              "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _vm.$_global_hasRouteClaims(
                                                "MailingResultsDashboard"
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "MailingResultsDashboard",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId,
                                                                    mailingItemId:
                                                                      item.mailingItemId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Real Time Results\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$_global_hasRouteClaims(
                                                "SingleMailingReport"
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "SingleMailingReport",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId,
                                                                    mailingItemId:
                                                                      item.mailingItemId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Single Mailing Report\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Reporting")])]
                        )
                      : _vm._e(),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          right: "",
                          "x-offset": "",
                          origin: "right",
                          transition: "slide-x-transition"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onMenu = ref.on
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var onTooltip = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mx-1",
                                                      attrs: {
                                                        disabled:
                                                          item.sentOn !== null,
                                                        small: "",
                                                        color: "success"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    Object.assign(
                                                      {},
                                                      onMenu,
                                                      onTooltip
                                                    )
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                mdi-send\n              "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v("Process Now")])]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-text", { staticClass: "pa-5" }, [
                              _c("span", [
                                _vm._v(
                                  "Are you sure you want to schedule this mailing with Acoustic?"
                                )
                              ])
                            ]),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c("v-btn", { attrs: { color: "tertiary" } }, [
                                  _vm._v("\n              Close\n            ")
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.scheduleProdMailing(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Yes, Process now\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          small: "",
                                          disabled: item.azTALocation === null,
                                          color: "success"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadTA(item)
                                          }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            mdi-bullseye-arrow\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Download Target Audience")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "", color: "info" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$_dashMixin_campaignHistory(
                                              item.campaignId
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            mdi-book-open-page-variant\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Campaign History")])]
                    ),
                    item.campaignStatus ===
                      _vm.$_campaignStatus_Completed /* && item.campaignFinished */ &&
                    (_vm.$_global_hasRouteClaims("CampaignEventsHeatmap") ||
                      _vm.$_global_hasRouteClaims("CampaignReport"))
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var onTooltip = ref.on
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onMenu = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              color: "info"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        Object.assign(
                                                          {},
                                                          onTooltip,
                                                          onMenu
                                                        )
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                mdi-chart-box\n              "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _vm.$_global_hasRouteClaims(
                                                "CampaignEventsHeatmap"
                                              ) &&
                                              _vm.$_dashMixin_isGeoDataFileTypeId(
                                                item.targetAudienceFileTypeId
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "CampaignEventsHeatmap",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Campaign Heatmap\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$_global_hasRouteClaims(
                                                "CampaignReport"
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "CampaignReport",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Campaign Report\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Reporting")])]
                        )
                      : _vm._e(),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onTooltip = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { icon: "", small: "" }
                                      },
                                      Object.assign({}, onTooltip)
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "\n              mdi-chevron-" +
                                            _vm._s(item._expandedIcon) +
                                            "\n            "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("See details")])]
                    )
                  ]
                }
              }
            ])
          }),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", "max-width": "1400" },
          model: {
            value: _vm.htmlDialog,
            callback: function($$v) {
              _vm.htmlDialog = $$v
            },
            expression: "htmlDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "pa-5", staticStyle: { "max-height": "75vh" } },
                [
                  _c("MailingPreview", {
                    attrs: { "mailing-item-id": _vm.mailingItemIdDialog }
                  })
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: {
                        click: function($event) {
                          _vm.htmlDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Close\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pb-4", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8", sm: "8", md: "5", lg: "5" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Subject",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.searchMailingsRequest.subjectSearch,
                  callback: function($$v) {
                    _vm.$set(_vm.searchMailingsRequest, "subjectSearch", $$v)
                  },
                  expression: "searchMailingsRequest.subjectSearch"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex",
              attrs: { cols: "4", sm: "4", md: "4", lg: "4" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "From Address",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.searchMailingsRequest.fromAddressSearch,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.searchMailingsRequest,
                      "fromAddressSearch",
                      $$v
                    )
                  },
                  expression: "searchMailingsRequest.fromAddressSearch"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: "From Domain",
                  prefix: "@",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.searchMailingsRequest.fromDomainSearch,
                  callback: function($$v) {
                    _vm.$set(_vm.searchMailingsRequest, "fromDomainSearch", $$v)
                  },
                  expression: "searchMailingsRequest.fromDomainSearch"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", sm: "2", md: "2", lg: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "success", small: "" },
                  on: { click: _vm.searchMailings }
                },
                [_c("v-icon", [_vm._v("\n          mdi-magnify\n        ")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.searchLoading
        ? _c("v-skeleton-loader", { attrs: { type: "table" } })
        : _c("v-data-table", {
            attrs: {
              "item-key": "index",
              headers: _vm.headers,
              items: _vm.searchedMailings,
              "item-class": _vm.rowColor,
              "items-per-page": 10,
              "custom-sort": _vm.tableSort,
              expanded: _vm.searchExpanded,
              "sort-by": ["scheduleDateLocal"],
              "sort-desc": "",
              dense: ""
            },
            on: { "click:row": _vm.toggleExpandRowSearch },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function(ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "px-0",
                        attrs: { colspan: headers.length }
                      },
                      [
                        _c("v-simple-table", {
                          attrs: { dense: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Campaign Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Mailing Item Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Tenant CRM Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  TA Id\n                "
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(_vm._s(item.campaignId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.mailingItemId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.tenantCrmId))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.targetAudienceId))
                                        ])
                                      ])
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("v-divider"),
                        _c("v-simple-table", {
                          attrs: { dense: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  List Name\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Mailing Name\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Aco Mailing Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Job Id\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Job Result\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Failed Reason\n                "
                                          )
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v(
                                            "\n                  Sent Count\n                "
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(item.acousticMailings, function(
                                        acoMailing
                                      ) {
                                        return _c(
                                          "tr",
                                          { key: acoMailing.id },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(acoMailing.id))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.listName)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.mailingName)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.mailingId)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(acoMailing.jobId))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  acoMailing.jobStatusResult
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  acoMailing.failedStatusReason
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(acoMailing.sendCount)
                                              )
                                            ])
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm.$_dashMixin_getMailingIconColor(
                                            item
                                          ),
                                          left: ""
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$_dashMixin_getMailingIcon(item)
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(item.statusDisplay))])]
                    )
                  ]
                }
              },
              {
                key: "item.distribution",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(item.distribution.join(", ")) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.modifiedOnLocal",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("displayDateTimeFormat")(item.modifiedOnLocal)
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.scheduleDateLocal",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("displayDateTimeFormat")(
                            item.scheduleDateLocal
                          )
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.sentOnLocal",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("displayDateTimeFormat")(item.sentOnLocal)
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "item.taCount",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.taNthSubCount === null
                      ? _c("div", [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm._f("commaNumber")(item.taCount)) +
                              "\n      "
                          )
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("commaNumber")(item.taNthSubCount)
                              ) +
                              "/" +
                              _vm._s(_vm._f("commaNumber")(item.taCount)) +
                              "\n      "
                          )
                        ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onTooltip = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mx-1",
                                        attrs: { small: "", color: "info" },
                                        on: {
                                          click: function($event) {
                                            _vm.mailingItemIdDialog =
                                              item.mailingItemId
                                            _vm.htmlDialog = true
                                          }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      Object.assign({}, onTooltip)
                                    ),
                                    [
                                      _vm._v(
                                        "\n            mdi-text-box\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Mailing Details")])]
                    ),
                    _vm.$_global_hasRouteClaims("MailingResultsDashboard") ||
                    _vm.$_global_hasRouteClaims("SingleMailingReport")
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var onTooltip = ref.on
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onMenu = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "mx-1",
                                                            attrs: {
                                                              small: "",
                                                              color: "info"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        Object.assign(
                                                          {},
                                                          onTooltip,
                                                          onMenu
                                                        )
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                mdi-chart-box\n              "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _vm.$_global_hasRouteClaims(
                                                "MailingResultsDashboard"
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "MailingResultsDashboard",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId,
                                                                    mailingItemId:
                                                                      item.mailingItemId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Real Time Results\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$_global_hasRouteClaims(
                                                "SingleMailingReport"
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "SingleMailingReport",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId,
                                                                    mailingItemId:
                                                                      item.mailingItemId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Single Mailing Report\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Reporting")])]
                        )
                      : _vm._e(),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          right: "",
                          "x-offset": "",
                          origin: "right",
                          transition: "slide-x-transition"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onMenu = ref.on
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var onTooltip = ref.on
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mx-1",
                                                      attrs: {
                                                        disabled:
                                                          item.sentOn !== null,
                                                        small: "",
                                                        color: "success"
                                                      },
                                                      nativeOn: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        }
                                                      }
                                                    },
                                                    Object.assign(
                                                      {},
                                                      onMenu,
                                                      onTooltip
                                                    )
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "\n                mdi-send\n              "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [_c("span", [_vm._v("Process Now")])]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-text", { staticClass: "pa-5" }, [
                              _c("span", [
                                _vm._v(
                                  "Are you sure you want to schedule this mailing with Acoustic?"
                                )
                              ])
                            ]),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c("v-btn", { attrs: { color: "tertiary" } }, [
                                  _vm._v("\n              Close\n            ")
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.scheduleProdMailing(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Yes, Process now\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          small: "",
                                          disabled: item.azTALocation === null,
                                          color: "success"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.downloadTA(item)
                                          }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            mdi-bullseye-arrow\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Download Target Audience")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "", color: "info" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$_dashMixin_campaignHistory(
                                              item.campaignId
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            mdi-book-open-page-variant\n          "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Campaign History")])]
                    ),
                    item.campaignStatus === _vm.$_campaignStatus_Completed &&
                    (_vm.$_global_hasRouteClaims("CampaignEventsHeatmap") ||
                      _vm.$_global_hasRouteClaims("CampaignReport"))
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var onTooltip = ref.on
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onMenu = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              color: "info"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        Object.assign(
                                                          {},
                                                          onTooltip,
                                                          onMenu
                                                        )
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                mdi-chart-box\n              "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _vm.$_global_hasRouteClaims(
                                                "CampaignEventsHeatmap"
                                              ) &&
                                              _vm.$_dashMixin_isGeoDataFileTypeId(
                                                item.targetAudienceFileTypeId
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "CampaignEventsHeatmap",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Campaign Heatmap\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$_global_hasRouteClaims(
                                                "CampaignReport"
                                              )
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "CampaignReport",
                                                                  params: {
                                                                    campaignId:
                                                                      item.campaignId
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    Campaign Report\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Reporting")])]
                        )
                      : _vm._e(),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var onTooltip = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { icon: "", small: "" }
                                      },
                                      Object.assign({}, onTooltip)
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "\n              mdi-chevron-" +
                                            _vm._s(item._expandedIcon) +
                                            "\n            "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("See details")])]
                    )
                  ]
                }
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }