<template>
  <div>
    <v-row
      align="center"
      class="pb-4"
    >
      <v-col
        class=""
        cols="6"
        sm="6"
        md="4"
        lg="4"
      >
        <ViewAsCustomer />
      </v-col>
      <v-col
        class=""
        cols="6"
        sm="6"
        md="4"
        lg="4"
      >
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Date range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dates"
            range
          />
        </v-menu>
      </v-col>
      <v-col
        class=""
        cols="6"
        sm="6"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loading"
      type="table"
    />
    <v-data-table
      v-else
      item-key="index"
      :headers="computedHeaders"
      :items="filteredMailings"
      :item-class="rowColor"
      :items-per-page="50"
      :search.sync="search"
      :custom-sort="tableSort"
      :expanded="expanded"
      :sort-by="['scheduleDateLocal']"
      sort-desc
      dense
      @click:row="toggleExpandRow"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="px-0"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Campaign Id
                  </th>
                  <th class="text-left">
                    Mailing Item Id
                  </th>
                  <th class="text-left">
                    Tenant CRM Id
                  </th>
                  <th class="text-left">
                    TA Id
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.campaignId }}</td>
                  <td>{{ item.mailingItemId }}</td>
                  <td>{{ item.tenantCrmId }}</td>
                  <td>{{ item.targetAudienceId }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider />
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Id
                  </th>
                  <th class="text-left">
                    List Name
                  </th>
                  <th class="text-left">
                    Mailing Name
                  </th>
                  <th class="text-left">
                    Aco Mailing Id
                  </th>
                  <th class="text-left">
                    Job Id
                  </th>
                  <th class="text-left">
                    Job Result
                  </th>
                  <th class="text-left">
                    Failed Reason
                  </th>
                  <th class="text-left">
                    Sent Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="acoMailing in item.acousticMailings"
                  :key="acoMailing.id"
                >
                  <td>{{ acoMailing.id }}</td>
                  <td>{{ acoMailing.listName }}</td>
                  <td>{{ acoMailing.mailingName }}</td>
                  <td>{{ acoMailing.mailingId }}</td>
                  <td>{{ acoMailing.jobId }}</td>
                  <td>{{ acoMailing.jobStatusResult }}</td>
                  <td>{{ acoMailing.failedStatusReason }}</td>
                  <td>{{ acoMailing.sendCount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              :color="$_dashMixin_getMailingIconColor(item)"
              left
              v-on="on"
            >
              {{ $_dashMixin_getMailingIcon(item) }}
            </v-icon>
          </template>
          <span>{{ item.statusDisplay }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.distribution="{ item }">
        {{ item.distribution.join(', ') }}
      </template>
      <template v-slot:item.modifiedOnLocal="{ item }">
        {{ item.modifiedOnLocal | displayDateTimeFormat }}
      </template>
      <template v-slot:item.scheduleDateLocal="{ item }">
        {{ item.scheduleDateLocal | displayDateTimeFormat }}
      </template>
      <template v-slot:item.sentOnLocal="{ item }">
        {{ item.sentOnLocal | displayDateTimeFormat }}
      </template>
      <template v-slot:item.taCount="{ item }">
        <div v-if="item.taNthSubCount === null">
          {{ item.taCount | commaNumber }}
        </div>
        <div v-else>
          {{ item.taNthSubCount | commaNumber }}/{{ item.taCount | commaNumber }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-icon
              small
              class="mx-1"
              color="info"
              @click.native.stop
              v-on="{ ...onTooltip }"
              @click="mailingItemIdDialog = item.mailingItemId; htmlDialog = true"
            >
              mdi-text-box
            </v-icon>
          </template>
          <span>Mailing Details</span>
        </v-tooltip>
        <v-tooltip
          v-if="$_global_hasRouteClaims('MailingResultsDashboard') || $_global_hasRouteClaims('SingleMailingReport')"
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-menu offset-y>
              <template v-slot:activator="{ on: onMenu, attrs }">
                <v-icon
                  small
                  class="mx-1"
                  color="info"
                  v-bind="attrs"
                  v-on="{ ...onTooltip, ...onMenu }"
                >
                  mdi-chart-box
                </v-icon>
              </template>
              <v-list>
                <v-list-item v-if="$_global_hasRouteClaims('MailingResultsDashboard')">
                  <v-list-item-title>
                    <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId: item.campaignId, mailingItemId: item.mailingItemId} }">
                      Real Time Results
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$_global_hasRouteClaims('SingleMailingReport')">
                  <v-list-item-title>
                    <router-link :to="{ name: 'SingleMailingReport', params: { campaignId: item.campaignId, mailingItemId: item.mailingItemId} }">
                      Single Mailing Report
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>Reporting</span>
        </v-tooltip>
        <v-menu
          right
          x-offset
          origin="right"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on: onTooltip }">
                <v-icon
                  :disabled="item.sentOn !== null"
                  small
                  class="mx-1"
                  color="success"
                  @click.native.stop
                  v-on="{ ...onMenu, ...onTooltip }"
                >
                  mdi-send
                </v-icon>
              </template>
              <span>Process Now</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <span>Are you sure you want to schedule this mailing with Acoustic?</span>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
              >
                Close
              </v-btn>
              <v-btn
                color="success"
                @click="scheduleProdMailing(item)"
              >
                Yes, Process now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              small
              :disabled="item.azTALocation === null"
              class="mx-1"
              color="success"
              @click="downloadTA(item)"
              @click.native.stop
              v-on="on"
            >
              mdi-bullseye-arrow
            </v-icon>
          </template>
          <span>Download Target Audience</span>
        </v-tooltip>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              color="info"
              @click="$_dashMixin_campaignHistory(item.campaignId)"
              v-on="on"
            >
              mdi-book-open-page-variant
            </v-icon>
          </template>
          <span>Campaign History</span>
        </v-tooltip>
        <v-tooltip
          v-if="item.campaignStatus === $_campaignStatus_Completed /* && item.campaignFinished */ && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-menu offset-y>
              <template v-slot:activator="{ on: onMenu, attrs }">
                <v-icon
                  class="mr-2"
                  color="info"
                  v-bind="attrs"
                  v-on="{ ...onTooltip, ...onMenu }"
                >
                  mdi-chart-box
                </v-icon>
              </template>
              <v-list>
                <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(item.targetAudienceFileTypeId)">
                  <v-list-item-title>
                    <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: item.campaignId } }">
                      Campaign Heatmap
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                  <v-list-item-title>
                    <router-link :to="{ name: 'CampaignReport', params: { campaignId: item.campaignId } }">
                      Campaign Report
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>Reporting</span>
        </v-tooltip>
        <!-- <v-dialog
          v-model="item._logsDialog"
          scrollable
          persistent
        >
          <template v-slot:activator="{ on: onDialog }">
            <v-tooltip top>
              <template v-slot:activator="{ on: onTooltip }">
                <v-icon
                  small
                  class="mx-1"
                  color="info"
                  v-on="{ ...onDialog, ...onTooltip }"
                  @click.native.stop
                >
                  mdi-book-open-page-variant
                </v-icon>
              </template>
              <span>View Logs</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <span>API call to get logs here</span>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
                @click="item._logsDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <v-tooltip top>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              icon
              small
              style="cursor: pointer;"
              v-on="{ ...onTooltip }"
            >
              <v-icon>
                mdi-chevron-{{ item._expandedIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>See details</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="htmlDialog"
      scrollable
      max-width="1400"
    >
      <v-card>
        <v-card-text
          class="pa-5"
          style="max-height: 75vh"
        >
          <MailingPreview
            :mailing-item-id="mailingItemIdDialog"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="tertiary"
            @click="htmlDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      align="center"
      class="pb-4"
    >
      <v-col
        class=""
        cols="8"
        sm="8"
        md="5"
        lg="5"
      >
        <v-text-field
          v-model="searchMailingsRequest.subjectSearch"
          label="Subject"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="4"
        sm="4"
        md="4"
        lg="4"
      >
        <v-text-field
          v-model="searchMailingsRequest.fromAddressSearch"
          label="From Address"
          single-line
          hide-details
        />
        <v-text-field
          v-model="searchMailingsRequest.fromDomainSearch"
          label="From Domain"
          prefix="@"
          single-line
          hide-details
        />
      </v-col>
      <v-col
        class=""
        cols="2"
        sm="2"
        md="2"
        lg="2"
      >
        <v-btn
          color="success"
          small
          @click="searchMailings"
        >
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="searchLoading"
      type="table"
    />
    <v-data-table
      v-else
      item-key="index"
      :headers="headers"
      :items="searchedMailings"
      :item-class="rowColor"
      :items-per-page="10"
      :custom-sort="tableSort"
      :expanded="searchExpanded"
      :sort-by="['scheduleDateLocal']"
      sort-desc
      dense
      @click:row="toggleExpandRowSearch"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="px-0"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Campaign Id
                  </th>
                  <th class="text-left">
                    Mailing Item Id
                  </th>
                  <th class="text-left">
                    Tenant CRM Id
                  </th>
                  <th class="text-left">
                    TA Id
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.campaignId }}</td>
                  <td>{{ item.mailingItemId }}</td>
                  <td>{{ item.tenantCrmId }}</td>
                  <td>{{ item.targetAudienceId }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider />
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Id
                  </th>
                  <th class="text-left">
                    List Name
                  </th>
                  <th class="text-left">
                    Mailing Name
                  </th>
                  <th class="text-left">
                    Aco Mailing Id
                  </th>
                  <th class="text-left">
                    Job Id
                  </th>
                  <th class="text-left">
                    Job Result
                  </th>
                  <th class="text-left">
                    Failed Reason
                  </th>
                  <th class="text-left">
                    Sent Count
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="acoMailing in item.acousticMailings"
                  :key="acoMailing.id"
                >
                  <td>{{ acoMailing.id }}</td>
                  <td>{{ acoMailing.listName }}</td>
                  <td>{{ acoMailing.mailingName }}</td>
                  <td>{{ acoMailing.mailingId }}</td>
                  <td>{{ acoMailing.jobId }}</td>
                  <td>{{ acoMailing.jobStatusResult }}</td>
                  <td>{{ acoMailing.failedStatusReason }}</td>
                  <td>{{ acoMailing.sendCount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              :color="$_dashMixin_getMailingIconColor(item)"
              left
              v-on="on"
            >
              {{ $_dashMixin_getMailingIcon(item) }}
            </v-icon>
          </template>
          <span>{{ item.statusDisplay }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.distribution="{ item }">
        {{ item.distribution.join(', ') }}
      </template>
      <template v-slot:item.modifiedOnLocal="{ item }">
        {{ item.modifiedOnLocal | displayDateTimeFormat }}
      </template>
      <template v-slot:item.scheduleDateLocal="{ item }">
        {{ item.scheduleDateLocal | displayDateTimeFormat }}
      </template>
      <template v-slot:item.sentOnLocal="{ item }">
        {{ item.sentOnLocal | displayDateTimeFormat }}
      </template>
      <template v-slot:item.taCount="{ item }">
        <div v-if="item.taNthSubCount === null">
          {{ item.taCount | commaNumber }}
        </div>
        <div v-else>
          {{ item.taNthSubCount | commaNumber }}/{{ item.taCount | commaNumber }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-icon
              small
              class="mx-1"
              color="info"
              @click.native.stop
              v-on="{ ...onTooltip }"
              @click="mailingItemIdDialog = item.mailingItemId; htmlDialog = true"
            >
              mdi-text-box
            </v-icon>
          </template>
          <span>Mailing Details</span>
        </v-tooltip>

        <v-tooltip
          v-if="$_global_hasRouteClaims('MailingResultsDashboard') || $_global_hasRouteClaims('SingleMailingReport')"
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-menu offset-y>
              <template v-slot:activator="{ on: onMenu, attrs }">
                <v-icon
                  small
                  class="mx-1"
                  color="info"
                  v-bind="attrs"
                  v-on="{ ...onTooltip, ...onMenu }"
                >
                  mdi-chart-box
                </v-icon>
              </template>
              <v-list>
                <v-list-item v-if="$_global_hasRouteClaims('MailingResultsDashboard')">
                  <v-list-item-title>
                    <router-link :to="{ name: 'MailingResultsDashboard', params: { campaignId: item.campaignId, mailingItemId: item.mailingItemId} }">
                      Real Time Results
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$_global_hasRouteClaims('SingleMailingReport')">
                  <v-list-item-title>
                    <router-link :to="{ name: 'SingleMailingReport', params: { campaignId: item.campaignId, mailingItemId: item.mailingItemId} }">
                      Single Mailing Report
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>Reporting</span>
        </v-tooltip>
        <v-menu
          right
          x-offset
          origin="right"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on: onTooltip }">
                <v-icon
                  :disabled="item.sentOn !== null"
                  small
                  class="mx-1"
                  color="success"
                  @click.native.stop
                  v-on="{ ...onMenu, ...onTooltip }"
                >
                  mdi-send
                </v-icon>
              </template>
              <span>Process Now</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <span>Are you sure you want to schedule this mailing with Acoustic?</span>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
              >
                Close
              </v-btn>
              <v-btn
                color="success"
                @click="scheduleProdMailing(item)"
              >
                Yes, Process now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              small
              :disabled="item.azTALocation === null"
              class="mx-1"
              color="success"
              @click="downloadTA(item)"
              @click.native.stop
              v-on="on"
            >
              mdi-bullseye-arrow
            </v-icon>
          </template>
          <span>Download Target Audience</span>
        </v-tooltip>
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              color="info"
              @click="$_dashMixin_campaignHistory(item.campaignId)"
              v-on="on"
            >
              mdi-book-open-page-variant
            </v-icon>
          </template>
          <span>Campaign History</span>
        </v-tooltip>
        <v-tooltip
          v-if="item.campaignStatus === $_campaignStatus_Completed && ($_global_hasRouteClaims('CampaignEventsHeatmap') || $_global_hasRouteClaims('CampaignReport'))"
          top
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-menu offset-y>
              <template v-slot:activator="{ on: onMenu, attrs }">
                <v-icon
                  class="mr-2"
                  color="info"
                  v-bind="attrs"
                  v-on="{ ...onTooltip, ...onMenu }"
                >
                  mdi-chart-box
                </v-icon>
              </template>
              <v-list>
                <v-list-item v-if="$_global_hasRouteClaims('CampaignEventsHeatmap') && $_dashMixin_isGeoDataFileTypeId(item.targetAudienceFileTypeId)">
                  <v-list-item-title>
                    <router-link :to="{ name: 'CampaignEventsHeatmap', params: { campaignId: item.campaignId } }">
                      Campaign Heatmap
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="$_global_hasRouteClaims('CampaignReport')">
                  <v-list-item-title>
                    <router-link :to="{ name: 'CampaignReport', params: { campaignId: item.campaignId } }">
                      Campaign Report
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>Reporting</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              icon
              small
              style="cursor: pointer;"
              v-on="{ ...onTooltip }"
            >
              <v-icon>
                mdi-chevron-{{ item._expandedIcon }}
              </v-icon>
            </v-btn>
          </template>
          <span>See details</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import { inputDateFormat } from '@/shared/constants'
import { downloadFileMixin, enumsMixin } from '@/shared/mixins/general'
import { dashboardMixin } from '@/shared/mixins/mailing'
import { mailingService, azBlobService } from '@/shared/services'

export default {
  name: 'CampaignAdmin',

  components: {
    MailingPreview: () => import('@/views/pages/components/mailing/MailingPreview'),
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  mixins: [downloadFileMixin, dashboardMixin, enumsMixin],

  data () {
    return {
      loading: false,
      search: undefined,
      dateMenu: false,
      dates: [
        // moment().add(-2, 'M').format(inputDateFormat),
        moment().add(-2, 'd').format(inputDateFormat),
        moment().add(2, 'd').format(inputDateFormat)
      ],
      expanded: [],
      headers: [
        { text: 'Tenant', value: 'tenantName' },
        { text: 'NOWW Job #', value: 'nowwOrderNumber' },
        { text: 'Campaign', value: 'campaignName' },
        { text: 'Behalf Of', value: 'campaignOnBehalfOfName' },
        { text: 'Mailing', value: 'mailingName' },
        { text: 'Mailing Status', value: 'status' },
        { text: 'Dist', value: 'distribution' },
        { text: 'Resend Type', value: 'resendTypeDisplay' },
        { text: 'Send Type', value: 'sendTypeDisplay' },
        { text: 'Sequence', value: 'sequence' },
        { text: 'Modified', value: 'modifiedOnLocal' },
        { text: 'Schedule Date', value: 'scheduleDateLocal' },
        { text: 'Processed', value: 'sentOnLocal' },
        { text: 'TA Count', value: 'taCount' },
        { text: 'Actual Count', value: 'actualAudienceCount' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          width: 110
        }
      ],
      mailings: [],
      htmlDialog: false,
      mailingItemIdDialog: null,
      searchLoading: false,
      searchedMailings: [],
      searchExpanded: [],
      searchMailingsRequest: {
        subjectsSearch: '',
        fromAddressSearch: '',
        fromDomainSearch: ''
      }
    }
  },

  computed: {
    customerHeaders () {
      return this.headers.filter(x => x.value !== 'tenantName')
    },
    computedHeaders () {
      return this.$store.getters['isViewAllCustomers']()
        ? this.headers.filter(x => !x.hidden)
        : this.customerHeaders.filter(x => !x.hidden)
    },
    filteredMailings () {
      return this.$store.getters['isViewAllCustomers']()
        ? this.mailings
        : this.mailings.filter(x => x.tenantCrmId === this.$store.getters['simulatedCustomerCrmId']())
    },
    dateRangeText () {
      return this.dates.join(' - ')
    }
  },

  watch: {
    dates: {
      handler (newValue, oldValue) {
        if (newValue && newValue.length === 2) {
          this.loadMailingsAdmin()
        }
      }
    }
  },

  created () {
    this.loadMailingsAdmin()
  },

  methods: {
    async loadMailingsAdmin () {
      this.loading = true
      var resp = await mailingService.getWPScheduledMailingsDetailed(this.dates[0], this.dates[1])
      this.mailings = resp.map((x, index) => (
        {
          index,
          ...x,
          modifiedOnLocal: x.modifiedOn ? moment(this.$_global_parseAsUtc(x.modifiedOn)).local() : null,
          scheduleDateLocal: x.scheduleDate ? moment(this.$_global_parseAsUtc(x.scheduleDate)).local() : null,
          sentOnLocal: x.sentOn ? moment(this.$_global_parseAsUtc(x.sentOn)).local() : null,
          _expandedIcon: 'down',
          _logsDialog: false
        }
      ))
      this.loading = false
    },
    async searchMailings () {
      this.searchLoading = true
      var resp = await mailingService.getWPScheduledMailingsByResults(this.searchMailingsRequest)
      this.searchedMailings = resp.map((x, index) => (
        {
          index,
          ...x,
          modifiedOnLocal: x.modifiedOn ? moment(this.$_global_parseAsUtc(x.modifiedOn)).local() : null,
          scheduleDateLocal: x.scheduleDate ? moment(this.$_global_parseAsUtc(x.scheduleDate)).local() : null,
          sentOnLocal: x.sentOn ? moment(this.$_global_parseAsUtc(x.sentOn)).local() : null,
          _expandedIcon: 'down',
          _logsDialog: false
        }
      ))
      this.searchLoading = false
    },
    tableSort (items, index, isDescending) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items
      }
      items.sort((a, b) => {
        if (!isDescending[0]) {
          return a[index[0]] > b[index[0]] ? 1 : -1
        } else {
          return b[index[0]] > a[index[0]] ? 1 : -1
        }
      })
      return items
    },
    rowColor (item) {
      const scheduleDate = new Date(item.scheduleDateLocal)
      const currentDate = new Date()
      if (item.isProcessing) return 'ins_processing'
      if (item.sentOn) return 'ins_sent'
      if (moment().local().isAfter(item.scheduleDate)) return 'ins_failed'
      if (scheduleDate < currentDate && item.statusDisplay === 'Scheduled') return 'ins_failed'
      return 'ins_waiting'
    },
    toggleExpandRow (value) {
      if (this.expanded.includes(value)) {
        value._expandedIcon = 'down'
        this.expanded.splice(this.expanded.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.expanded.push(value)
      }
    },
    toggleExpandRowSearch (value) {
      if (this.searchExpanded.includes(value)) {
        value._expandedIcon = 'down'
        this.searchExpanded.splice(this.searchExpanded.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.searchExpanded.push(value)
      }
    },
    async scheduleProdMailing (mailing) {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Processing Mailing')
      await mailingService.scheduleProdMailing(mailing.mailingItemId, mailing.sendType)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$store.commit('setGlobalSnackbar', {
              message: `Successfully scheduled ${mailing.mailingName}!`,
              color: 'success'
            })
            // TODO: fix row so that btn is disabled
            var item = _this.mailings.filter(x => x.mailingItemId === mailing.mailingItemId && x.sendType === mailing.sendType)
            item.sentOn = moment().local()
          }
        })
    },
    async downloadTA (mailing) {
      var _this = this
      this.$store.commit('setLoadingMessage', 'Downloading')
      await azBlobService
        .downloadBlobs({
          tenantCrmId: mailing.tenantCrmId,
          root: 'targetAudiences/',
          blobs: [mailing.azTALocation]
        })
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    }
  }
}
</script>

<style lang="scss">
// .ins_waiting {
// }
.ins_processing {
  background-color: var(--v-accent-lighten5);
}
.ins_sent {
  background-color: var(--v-success-lighten5);
}
.ins_failed {
  background-color: var(--v-error-lighten5);
}

.v-data-table__wrapper td {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
</style>
